<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [],
      loading: false,
      messagelist: [],
      page: 1,
      userinfo: [],
      limit: 20,
      messagetotal: 0,
      setting: {
        status: false,
        authcode: "",
        deviceid: "",
        version: "",
        language: "zh-cn",
        autoprint: false,
      },
      template: {
        tempid: "ushengyun",
        text: "",
      },
      printertemplate: [],
      printerformlist: [
        {
          label: this.$t("clouds.printer.templates.logo"),
          type: "logo",
        },
        {
          label: this.$t("clouds.printer.templates.title"),
          type: "title",
          value: ""
        },

      ],
    };
  },
  mounted() {
    this.title = this.$t("menuitems.clouds.printer.text");
    this.items = [
      {
        text: this.$t("menuitems.clouds.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.clouds.printer.text"),
        active: true,
      },
    ];
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    //   this.page.title=this.$t('global.notice.type.notify')
    this.getprintersetting();
  },
  methods: {
    getprintersetting() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getprintersetting"
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          if (response.data.status == 200) {
            that.setting = response.data.setting;
            // that.template = response.data.template;
            // that.printertemplate = response.data.printertemplate;
          } else {
            that.$message.error(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onSubmit() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "saveprintersetting",
            data: that.setting,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          if (response.data.status == 200) {
            that.$message.success(response.data.msg);
            that.getprintersetting();
          } else {
            that.$message.error(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12" v-loading="loading">
        <div class="row">
          <div class="col-6">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>{{ $t("menuitems.clouds.printer.setting.text") }}</span>
              </div>
              <div>
                <el-form ref="form" :model="setting" label-width="80px">
                  <el-form-item :label="$t('clouds.printer.switch')">
                    <el-switch v-model="setting.status"></el-switch>
                  </el-form-item>
                  <el-form-item :label="$t('clouds.printer.serial')">
                    <el-input v-model="setting.authcode"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('clouds.printer.deviceid')">
                    <el-input v-model="setting.deviceid"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('clouds.printer.version')">
                    {{ setting.version }}
                  </el-form-item>
                  <el-form-item :label="$t('clouds.printer.language')">
                    <el-select v-model="setting.language" :placeholder="$t('clouds.printer.select_language')">
                      <el-option label="简体中文" value="zh-cn"></el-option>
                      <el-option label="繁體中文" value="zh-hk"></el-option>
                      <el-option label="English" value="en"></el-option>
                      <el-option label="日本語" value="ja-jp"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item :label="$t('clouds.printer.autoswitch')">
                    <el-switch v-model="setting.autoprint" v-if="setting.version != 'K4'" disabled></el-switch>
                    <el-switch v-model="setting.autoprint" v-else></el-switch>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="onSubmit">{{ $t("menuitems.clouds.printer.save") }}</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </el-card>
          </div>
          <div class="col-6">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>{{ $t("menuitems.clouds.printer.template.text") }}</span>
              </div>
              <div>
                <el-form ref="form" label-width="80px">
                  <el-form-item :label="$t('clouds.printer.template')">
                    <el-select v-model="template.tempid" :placeholder="$t('clouds.printer.select_template')">
                      <el-option label="优声云" value="ushengyun"></el-option>
                      <el-option label="易联云" value="yilianyun"></el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </div>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>